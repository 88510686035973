<template>
  <div class="share-box">
    <van-nav-bar class="nav-bar" :title="$t('home.withdrawalProfile')"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('home.withdrawalProfile') }}</span>
      </template>
    </van-nav-bar>
    <div class="share-content">
      <van-tabs v-model="active" @change="handleTab">
        <van-tab v-for="pos in tabList" :key="pos" :title="$t(pos)"></van-tab>
      </van-tabs>
      <div class="list-content-box">
        <van-list v-model="loading" :finished="finished" :finished-text="$t('recharge.noDataAvailable')" @load="onLoad"
          :loading-text="$t('common.loading')">
          <div class="list-content">
            <div class="list-item" v-for="(item, index) in list" :key="index">
              <div class="list-item-left">
                <span>{{ item.amount }}</span>
                <p>{{ getShowDateTime(item.create_time) }}</p>
              </div>
              <div class="list-item-right">
                <span>{{ $t(itemState[item.status]) }}</span>
                <p>{{ $t('share.the') }}:{{ item.id }}</p>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import { formatDate } from '@/utils/unique'

export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      active: 0,
      // this.$t('common.processing')
      tabList: ['home.all', 'withdraw.underReview', 'common.success', 'bank.failure'],
      list: [],
      loading: false,
      finished: false,
      pages: 1,
      itemState: {
        0: 'common.processing', // 未处理
        1: 'common.success', // 成功
        2: 'bank.failure' // 失败
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  mounted() { },
  methods: {
    /* handle */
    handleTab(key) {
      this.list = []
      this.pages = 1
      this.getListData()
    },
    /* API */
    onLoad() {
      this.getListData()
    },
    async getListData() {
      this.loading = true
      const { data } = await request({
        method: 'post',
        url: 'users/ref/orders',
        data: {
          status: !this.active ? null : this.active - 1,
          page: this.pages
        }
        // params: {
        //   amount: this.eeuiInpVal,
        // },
      })
      if (data.ret === 1) {
        if (data.data.data.length) {
          this.list.push(...data.data.data)
          this.pages++
        } else {
          this.finished = true
        }
        this.loading = false
      } else {
        this.$toast(data.msg)
      }
    },
    /* 其他 */
    getShowDateTime(val) {
      if (val) {
        return formatDate('Y-m-d H:i:s', val)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tab__text--ellipsis {
  -webkit-line-clamp: 2;
}

.share-box {
  height: 100vh;
  font-family: 'PingFang-Regular';
  position: relative;
  box-sizing: border-box;

  .nav-bar {
    width: 100%;
    // z-index: 9999;
  }

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }

    /deep/ .van-nav-bar__title {
      // color: #fff;
    }

    button {
      background-color: #eeeeee;
      border: none;
      border-radius: 5px;
      color: rgb(51, 20, 20);
      font-size: 34px;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }

    /deep/ .van-popover {
      .van-popover__content {
        width: 405px;
      }

      /deep/ .van-popover__action {
        /deep/ .van-popover__action-text {
          width: 100px;
        }
      }
    }
  }

  >.share-content {
    height: calc(100% - 92px);
  }

  .banner {
    width: 100%;
    height: 300px;
  }

  .navbar-title {
    font-size: 32px;
    color: #ffffff;
  }

  .banner-image {
    width: 100%;
    height: 300px;
  }

  .cell-space {
    width: 100%;
    height: 10px;
  }

  .cell-box {
    height: 98px;
    background-color: white;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1px;
  }

  .cell-left-box {
    flex-direction: row;
    margin-left: 32px;
    align-items: center;
  }

  .cell-left-image {
    width: 38px;
    height: 38px;
  }

  .cell-left-title {
    font-size: 30px;
    color: #333333;
    margin-left: 24px;
  }

  .cell-right-box {
    flex-direction: row;
    margin-right: 32px;
    align-items: center;
  }

  .cell-right-desc {
    color: #999999;
    font-size: 28px;
    margin-right: 14px;
  }

  .cell-right-image {
    width: 14px;
    height: 28px;
  }

  .bottom-view-box {
    width: 100%;
    background-color: white;
    flex-direction: column;
  }

  .section {
    flex-direction: row;
  }

  .row {
    width: 250px;
    height: 200px;
    background-color: white;
    border-color: #efefef;
    border-width: 1px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .row-icon {
    width: 70px;
    height: 70px;
  }

  .row-text {
    color: #333333;
    font-size: 28px;
    margin-top: 36px;
  }

  .navbar-right-title {
    width: 110px;
    height: 50px;
    text-align: center;
    font-size: 30px;
    color: #710805;
    background-color: #fff;
    margin-right: 15px;
    line-height: 50px;
    border-radius: 6px;
  }

  .tabel-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .tabel-item {
    width: 375px;
    height: 180px;
    background-color: white;
    border-color: #efefef;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .table-title {
    font-size: 28px;
    margin-top: 20px;
    color: #4f4f4f;
  }

  .item-key {
    font-size: 38px;
    color: #4d0000;
  }

  .list-cont {
    width: 100%;
    background: #fff;
  }

  .receive {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 120px;
    height: 45px;
  }

  .receive-img {
    width: 110px;
    height: 40px;
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .receive-text {
    font-size: 28px;
    color: #fff;
    position: absolute;
    top: 2px;
    right: 30px;
    z-index: 99;
  }

  .list-cont-title-div {
    width: 100%;
    height: 70px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: #efefef;
    border-bottom-width: 1px;
  }

  .list-cont-title {
    font-size: 30px;
    padding-left: 30px;
    color: #4f4f4f;
  }

  .cont-list {
    height: calc(100vh - 850px);
    overflow-y: auto;
  }

  .item-list {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: #efefef;
    border-bottom-width: 1px;
    font-size: 30px;
  }

  .item-img {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    margin-right: 10px;
  }

  .item-data {
    height: 40px;
    line-height: 40px;
    color: #4f4f4f;
  }

  .item-money {
    color: #ff0000;
  }

  .lottery-more {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
  }

  .more-top {
    width: 40px;
    height: 40px;
    margin-top: 6px;
    margin-right: 34px;
    color: #464646;
    font-size: 52px;
    content: 'tb-triangle-up-fill';
  }

  .more-box {
    position: absolute;
    top: 26px;
    right: 16px;
    width: 264px;
    border-radius: 12px;
    background-color: #464646;
  }

  .more-item {
    height: 86px;
    font-size: 26px;
    line-height: 86px;
    text-align: center;
    color: #ffffff;
  }

  .more-line {
    width: 264px;
    height: 1px;
    background-color: #333333;
  }
}

.commission-box {
  width: 620px;
  padding: 40px;

  >.commission-title {
    font-size: 34px;
  }

  >input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #008577;
    font-size: 34px;
  }

  >.commission-btn-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;

    >p {
      font-size: 32px;
      margin-left: 30px;
    }

    >p:nth-last-child(1) {
      color: #008577;
    }
  }
}

.list-content-box {
  width: 100%;
  height: calc(100vh - 180px);
  background-color: #fff;

  .list-item {
    width: calc(100% - 40px);
    padding: 20px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 32px;

    >div {
      >span {
        color: #ff0000;
      }

      >p {
        font-size: 28px;
        margin-top: 20px;
      }
    }
  }
}
</style>
